import { CmsPageFields } from '@scandipwa/scandipwa/src/query/CmsPage.type';
import { debounce } from '@scandipwa/scandipwa/src/util/Request/Debounce';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
    CmsPageContainer as SourceCmsPageContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceRoute/CmsPage/CmsPage.container';
import { scrollToTop } from 'SourceUtil/Browser';
import {updateCmsPage} from 'Store/Cms/Cms.action';
import { RootState } from 'Util/Store/Store.type';

import { LOADING_TIME } from './CmsPage.config';
/** @namespace Inov8/Route/CmsPage/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

/** @namespace Inov8/Route/CmsPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateWithInitialCmsPage: (cmsPage: CmsPageFields) => {
        dispatch(updateCmsPage(cmsPage));
    },
    // TODO extend mapDispatchToProps
});

/** @namespace Inov8/Route/CmsPage/Container */
export class CmsPageContainer extends SourceCmsPageContainer {
    componentDidMount() {
        const {
            isOffline,
            isOnlyPlaceholder,
            isLoading,
            requestPage,
        } = this.props;

        scrollToTop();

        if (isOffline && isLoading) {
            debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        }

        if (!isOnlyPlaceholder) {
            const params = this.getRequestQueryParams();

            requestPage(params);
        }
    }

    requestPage(): void {
        const { requestPage, updateWithInitialCmsPage } = this.props;
        const params = this.getRequestQueryParams();
        const { id, identifier = '' } = params;
        const {
            actionName: {
                id: pageId = null,
                cmsPage: {
                    identifier: pageIdentifier = null,
                } = {},
                cmsPage,
            } = {},
        } = window;

        if (!id && !identifier) {
            return;
        }

        if (!id) {
            return;
        }

        // vvv check if cms page was already loaded from action
        if (
            id === pageId
            || identifier.replace(/^\/+/, '') === pageIdentifier
        ) {
            if (updateWithInitialCmsPage && cmsPage) {
                updateWithInitialCmsPage(cmsPage);
            }

            return;
        }

        requestPage(params);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsPageContainer);
